/* MoreOptionsDropdown.css */

.more-options-dropdown {
    position: relative;
    display: inline-block;
}

.more-options-button {
    background-color: #874daf;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 1rem;
}

.more-options-button:hover {
    background-color: #9b77b5;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.more-options-dropdown:hover .dropdown-content {
    display: block;
}


@media screen and (max-width: 680px) {

    .more-options-button {
        padding: 0.2rem 0.5rem;
        font-size: 0.9rem;
        margin-right: 0.1rem;
    }

    .dropdown-content {
        min-width: 100px;
        z-index: 2;
        margin-top: 5px;
    }

    .dropdown-content a {
        padding: 10px 14px;
        font-size: 15px;
    }
}

@media only screen and (min-width: 680px) and (max-width: 900px) {
    .more-options-button {
        padding: 0.3rem 0.6rem;
        font-size: 0.9rem;
        margin-right: 1rem;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1050px) {
    .more-options-button {
        padding: 0.4rem 0.9rem;
        font-size: 1rem;
        margin-right: 1rem;
    }
}