.navbar {
  background: #00a0f8;
  width: 95%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0px 25px 0px 25px;
  border-radius: 15px;
  margin: 20px;
  z-index: 1;
}

.navbar_logo {
  color: #fff;
  justify-self: start;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
}




/* SideBarr */
.nav-menu {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  justify-content: flex-start;
  position: absolute;
  padding-bottom: 75px;
  top: 0px;
  left: -50%;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 11;
  overflow: auto;
}

.nav-menu.active {
  background: #242222;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 11;
}

.nav-menu::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

.nav-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.nav-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #00a0f8;
}


.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: center;
}

.nav-links {
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 2rem;
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links.active {
  padding: 0.5rem 1rem;
  width: 95%;
  height: 55px;
  color: white;
  background-color: #3eafec;
  border-radius: 4px;
  text-decoration: none;
}

.nav-links:hover {
  width: 95%;
  height: 100%;
  color: white;
  background-color: #3eafec;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
}

.menu_icon .fas {
  /* display: none; */
  color: black;
  font-size: 1.5rem;
}

.fa-times {
  display: none;
}

.fa-times {
  display: initial;
  color: #fff;
  font-size: 1.8rem;
  align-self: flex-end;
  margin: 25px 23px 15px 0px;
  cursor: pointer;
}

.butnidshortlgot {
  display: none;
}

@media screen and (max-width: 680px) {
  .navbar {
    height: 60px;
    font-size: 0.8rem;
    border-radius: 10px;
  }

  .navbar_logo {
    font-size: 1.1rem;
  }

  .nav-menu {
    width: 65%;
  }

  .nav-links {
    font-size: 15px;
  }

  .nav-links.active {
    font-size: 15px;
  }

  .nav-links:hover {
    font-size: 15px;
  }

}

@media only screen and (min-width: 680px) and (max-width: 900px) {
  .navbar {
    height: 70px;
    font-size: 0.9rem;
  }

  .navbar_logo {
    font-size: 1.4rem;
  }

  .nav-menu {
    width: 40%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1050px) {
  .navbar {
    height: 80px;
    font-size: 0.9rem;
  }

  .navbar_logo {
    font-size: 1.6rem;
  }

  .nav-menu {
    width: 30%;
  }
}