.excel_Background {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
}

.excelContainer {
    width: 60%;
    border-radius: 12px;
    background-color: #eaeef3;
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin: 15px 0px 15px 0px;
}

.excelContainer .delete_text {
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.excelContainer .imagelogoonexcel {
    width: 250px;
    height: 150px;
    align-self: center;
    margin-bottom: 30px;
    object-fit: contain;
}

.custom_file_input {
    padding: 8px 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #eaeef3;
    background-color: #eaeef3;
    border-radius: 5px;
    color: white;
    margin-top: 60px;
    font-size: medium;
    transition: 0.3s ease-in-out;
}

.custom_file_input .prfilebtn {
    text-decoration: none;
    padding: 8px 30px;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #1a6aff;
    color: white;
    border: 1px solid #1a6aff;
    font-size: medium;
    transition: 0.3s ease-in-out;
}

.custom_file_input .prfilebtn:hover {
    background-color: #f1f5fc;
    color: #1a6aff;
}

.custom_file_input input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.custom_file_input:hover {
    background-color: #f1f5fc;
    color: #1a6aff;
}


.uploadexclbuttoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}