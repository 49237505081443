* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
}

.topbar_page {
    width: 100%;
    background-color: #fcf7ed;
}

.link {
    text-decoration: none;
    color: inherit;
}