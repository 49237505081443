.flash_diaground {
    background-color: rgba(2, 2, 2, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 1;
}

.flash_diaground .flashdiaContainer {
    width: 35%;
    height: 150px;
    border-radius: 12px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
}

.flash_diaground .flashdiaContainer::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

.flash_diaground .flashdiaContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.flash_diaground .flashdiaContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #00a0f8;
}

.flashdiaContainer .titleflashdiaCloseBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 15px 0px 10px;
}

.flashdiaContainer .titleflashdiaCloseBtn h3 {
    margin-left: 15px;
    font-weight: 500;
}

.flashdiaContainer .titleflashdiaCloseBtn button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 5px;
}


.flashdiaContainer #paydocumention {
    color: black;
    font-weight: normal;
    font-size: 15px;
    margin: 18px 18px 0px 18px;
}



@media only screen and (min-width: 0px) and (max-width: 431px) {
    .flash_diaground .flashdiaContainer {
        width: 90%;
    }

    .flashdiaContainer .titleflashdiaCloseBtn {
        padding: 10px 12px 0px 8px;
    }

    .flashdiaContainer .titleflashdiaCloseBtn h3 {
        margin-left: 10px;
        font-size: 17px;
    }

    .flashdiaContainer #paydocumention {
        font-size: 14px;
        margin-left: 14px;
        margin-top: 13px;
    }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .flash_diaground .flashdiaContainer {
        width: 65%;
    }

    .flashdiaContainer .titleflashdiaCloseBtn {
        padding: 10px 12px 0px 8px;
    }

    .flashdiaContainer .titleflashdiaCloseBtn h3 {
        margin-left: 10px;
        font-size: 17px;
    }

    .flashdiaContainer #paydocumention {
        font-size: 14px;
        margin-left: 14px;
        margin-top: 13px;
    }
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
    .flash_diaground .flashdiaContainer {
        width: 50%;
    }
}