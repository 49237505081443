:root {
  --primary: #00a0f8;
}

.btn {
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}


@media screen and (max-width: 680px) {
  .btn {
    font-size: 13px;
  }
}

@media only screen and (min-width: 680px) and (max-width: 900px) {
  .btn {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1050px) {
  .btn {
    font-size: 17px;
  }
}