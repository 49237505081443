.services_detailground {
    background-color: rgba(2, 2, 2, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 1;
}

.services_detailground .servicedetailContainer {
    width: 35%;
    height: 510px;
    border-radius: 12px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
}

.services_detailground .servicedetailContainer::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

.services_detailground .servicedetailContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.services_detailground .servicedetailContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #00a0f8;
}

.servicedetailContainer .titleservicdetailCloseBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 15px 0px 10px;
}

.servicedetailContainer .titleservicdetailCloseBtn h3 {
    margin-left: 15px;
    font-weight: 500;
}

.servicedetailContainer .titleservicdetailCloseBtn button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 5px;
}


/* Payout Button */
.servicedetailContainer .chatfromservic_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00a0f8;
    padding: 12px 0px 12px 0px;
    width: 93%;
    align-self: center;
    border-radius: 7px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.servicedetailContainer .chatfromservic_btn {
    color: white;
}

/* ////////////////////////// */



@media only screen and (min-width: 0px) and (max-width: 431px) {
    .services_detailground .servicedetailContainer {
        width: 90%;
    }

    .servicedetailContainer .titleservicdetailCloseBtn {
        padding: 10px 12px 0px 8px;
    }

    .servicedetailContainer .titleservicdetailCloseBtn h3 {
        margin-left: 10px;
        font-size: 17px;
    }


    .servicedetailContainer .chatfromservic_btn {
        padding: 10px 0px 10px 0px;
        width: 92%;
    }

    .servicedetailContainer .chatfromservic_btn {
        font-size: 14px;
    }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .services_detailground .servicedetailContainer {
        width: 65%;
    }

    .servicedetailContainer .titleservicdetailCloseBtn {
        padding: 10px 12px 0px 8px;
    }

    .servicedetailContainer .titleservicdetailCloseBtn h3 {
        margin-left: 10px;
        font-size: 17px;
    }


    .servicedetailContainer .chatfromservic_btn {
        padding: 10px 0px 10px 0px;
        width: 92%;
    }

    .servicedetailContainer .chatfromservic_btn {
        font-size: 14px;
    }
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
    .services_detailground .servicedetailContainer {
        width: 50%;
    }
}